import { sdk } from "@gc/ipecs-web-sdk";
import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";

const UserClisContext = React.createContext();
const UserClisProvider = ({ children }) => {
  const { call, user } = sdk;

  const [userClis, setUserClis] = useState([]);
  const [selectedCli, setSelectedCli] = useState();
  const [cliSettingId, setCliSettingId] = useState();

  const myInfoQuery = useQuery({
    queryKey: ["ipecs-my-info"],
    queryFn: user.getMyInfo,
  });

  const userClisQuery = useQuery({
    queryKey: ["user-clis"],
    queryFn: call.getOutgoingCliList,
  });

  useEffect(() => {
    if (selectedCli) {
      call.setUserSettingsCli({
        userSettingId: cliSettingId,
        value: selectedCli,
      });
    }
  }, [selectedCli, cliSettingId, call]);

  useEffect(() => {
    setCliSettingId(myInfoQuery.data?.userSetting?.id);
    setSelectedCli(myInfoQuery.data?.userSetting?.selectedOutgoingCliId);
  }, [myInfoQuery.data]);

  useEffect(() => {
    setUserClis(
      (userClisQuery.data?.body?.data ?? []).map((d) => ({
        ...d.tenantOutgoingCli,
        id: d.id,
      })),
    );
  }, [userClisQuery.data]);

  const defaultContext = {
    userClis,
    selectedCli,
    setSelectedCli,
  };
  return (
    <UserClisContext.Provider value={defaultContext}>
      {children}
    </UserClisContext.Provider>
  );
};

function useUserClis() {
  return useContext(UserClisContext);
}

export { UserClisProvider, useUserClis };
