/*global chrome*/
import { click_to_dial_extension_id as ctdExtensionId } from "../config";

function closePopupDialler() {
  setTimeout(() => {
    window.chrome &&
      chrome?.runtime?.sendMessage(ctdExtensionId, {
        activity: "callEnded",
      });
  }, 500);
}

function focusIncomingCall() {
  window.chrome &&
    chrome?.runtime?.sendMessage(ctdExtensionId, {
      activity: "incomingCall",
    });
}

export { closePopupDialler, focusIncomingCall };
